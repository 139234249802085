<script setup>
import { inject } from 'vue'
import CourseitemComponent from './CourseitemComponent.vue'

const store = inject('store')

</script>

<template>
  <courseitem-component :course-type="'Engineering Maths tuition'" :corresponding-courses="store.state.courses.engg" :col-num="2"></courseitem-component>

  <courseitem-component :course-type="'A Levels & GCSE Maths tuition'" :corresponding-courses="store.state.courses.boards" :col-num="3"></courseitem-component>
</template>