import { readonly, reactive } from 'vue'

const state = reactive({
  brand: {
    first: 'Maths',
    second: 'Hour',
    third: 'UK',
    domain: '@gmail.com',
    caption: 'Maths tuition',
    email: 'MathsHourUK@gmail.com'
  },
  courses: {
    heading: 'Study Maths from anywhere with immersive tuition',
    subheading: 'Our live online Maths tuition classes give you the freedom to sculpt your study around your existing lifestyle.',
    engg: [
      {
        name: 'Engineering Maths',
        level: 'BSc. Engineering',
        desc: 'modules',
        img: 'stroud.jpg',
        fee: '£10 per month',
        unlimited: 'unlimited live classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 3rd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '2 classes per week',
        dropin: 'live online classes',
        techstack: ['algebra', 'calculus', 'complex analysis', 'linear programming'],
        boards: 'BSc. Engineering',
        examboards: ['BSc. Engineering degrees',],
      },
      {
        name: 'Advanced Engineering Maths',
        level: 'BSc. Engineering',
        desc: 'modules',
        img: 'stroud_adv.jpg',
        fee: '£10 per month',
        unlimited: 'unlimited live classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 3rd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '2 classes per week',
        dropin: 'live online classes',
        techstack: ['algebra', 'calculus', 'complex analysis', 'linear programming'],
        boards: 'BSc. Engineering',
        examboards: ['BSc. Engineering degrees',],
      }
    ],
    boards: [
      {
        name: 'A Level Maths',
        level: 'Year 2',
        desc: 'modules',
        img: 'ay2.jpg',
        fee: '£10 per month',
        unlimited: 'unlimited live classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 3rd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '4 classes per week',
        dropin: 'live online classes',
        techstack: ['pure maths', 'statistics', 'mechanics'],
        boards: 'All UK exam boards',
        examboards: ['AQA', 'Edexcel', 'OCR'],
      },
      {
        name: 'AS Level Maths',
        level: 'Year 1',
        desc: 'modules',
        img: 'ay1.jpg',
        fee: '£10 per month',
        unlimited: 'unlimited live classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 3rd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '4 classes per week',
        dropin: 'live online classes',
        techstack: ['pure maths', 'statistics', 'mechanics'],
        boards: 'All UK exam boards',
        examboards: ['AQA', 'Edexcel', 'OCR'],
      },
      {
        name: 'GCSE Maths',
        level: 'GCSE',
        desc: 'modules',
        img: 'gcse.jpg',
        fee: '£10 per month',
        unlimited: 'unlimited live classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 3rd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '4 classes per week',
        dropin: 'live online classes',
        techstack: ['Number', 'Algebra', 'Graphs', 'Ratio, proportion and rates of change', 'Geometry and measures', 'Pythagoras and trigonometry', 'Probability and statistics'],
        boards: 'All UK exam boards',
        examboards: ['AQA', 'Edexcel', 'OCR'],
      },
    ]
  },
  contact: {
    mobile: '074 2459 6325',
    email: 'MathsHourUK@gmail.com',
  },
  feeview: {
    paypal: 'https://www.paypal.me/MathsHour',
    fee: '£10 per month',
    unlimited: 'unlimited live classes',
        unmatchable: 'unmatchable tuition fee nationwide',
    payable: 'pay after your 3rd class every month',
    contract: 'no contract',
    advancepay: 'no advance payment',
    payOnly: 'pay if and only if you like the sessions',
  },

  timetable: [
    {
      name: 'Engineering Maths',
      level: 'BSc. Engineering',
      thumbnail: 'stroud.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '9am',
      sun: '9am',
    },
    {
      name: 'Advanced Engineering Maths',
      level: 'BSc. Engineering',
      thumbnail: 'stroud_adv.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '10am',
      sun: '10am',
    },
    {
      name: 'A Level Maths',
      level: 'Year 2',
      thumbnail: 'ay2.jpg',
      mon: '8pm',
      tue: '8pm',
      wed: '8pm',
      thu: '8pm',
      fri: '',
      sat: '',
      sun: '',
    },
    {
      name: 'AS Level Maths',
      level: 'Year 1',
      thumbnail: 'ay1.jpg',
      mon: '9pm',
      tue: '9pm',
      wed: '9pm',
      thu: '9pm',
      fri: '',
      sat: '',
      sun: '',
    },
    {
      name: 'GCSE Maths',
      level: 'GCSE',
      thumbnail: 'gcse.jpg',
      mon: '7am',
      tue: '7am',
      wed: '7am',
      thu: '7am',
      fri: '',
      sat: '',
      sun: '',
    },
  ],
})

const methods = {
  onLoad() {
    console.log('courses loaded')
  },
}

const getters = {

}

export default {
  state: readonly(state),
  methods,
  getters,
}