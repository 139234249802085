<script setup>
import { inject, onMounted } from 'vue'
import CoursesComponent from '@/components/CoursesComponent.vue'
import TimetableComponent from '@/components/TimetableComponent.vue'
import ContactComponent from '@/components/ContactComponent.vue'

const store = inject('store')

onMounted(() => {
  store.methods.onLoad()
})

</script>

<!-- IMPORTANT: Bootstrap tables have a default margin-bottom: 1rem so if you notice extra spacing anywhere, inspect for a <table> in use -->

<template>
  <section class="bg-dark">
    <div class="container">
      <courses-component></courses-component>
    </div>
  </section>

  <section class="bg-black">
    <div class="container">
      <timetable-component></timetable-component>
    </div>
  </section>

  <section class="bg-dark text-grey">
    <div class="container">
      <contact-component></contact-component>
    </div>
  </section>
</template>

