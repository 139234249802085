<script setup>
import { inject } from 'vue'

const store = inject('store')
</script>

<template>
  <div class="table-responsive">
    <table class="table table-sm table-dark text-center text-uppercase mb-1">
      <thead>
        <tr>
          <th scope="col" class="h3 display-5 text-uppercase text-center">Contact tutor</th>
        </tr>
      </thead>
      <tbody class="fs-6 fw-bold">
        <tr>
          <td class="fs-4 text-grey">Leo Santosh</td>
        </tr>
        <tr>
          <td class="text-grey">Masters - City, University of London</td>
        </tr>
        <tr>
          <td>
            {{ store.state.brand.first }}<span class="text-grey fst-italic">{{ store.state.brand.second }}</span>{{ store.state.brand.third }}<span class="text-grey">{{ store.state.brand.domain }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>