<script setup>
import { toRefs, defineProps } from 'vue'

const props = defineProps({
  courseType: String,
  correspondingCourses: Array,
  colNum: Number,
})

const {courseType, correspondingCourses} = toRefs(props)

</script>

<template>
  <div class="row bg-secondary mt-4 bg-course">
    <div class="col-sm-12">
      <h3 class="display-5 text-uppercase text-center text-dark my-4">{{ courseType }}</h3>
    </div>
    
    <div v-for="(course, index) of correspondingCourses" :key="index" class="col-sm-12" :class="{'col-md-6': colNum === 2, 'col-md-4': colNum === 3}">
      <div class="table-responsive">
        <table class="table table-dark table-striped text-center text-grey">
          <thead>
            <tr>
              <th class="text-muted text-uppercase" scope="col">
                <span class="fs-4 fw-bold text-light">{{ course.name }}</span><br>
                <small class="fw-bold text-grey">{{ course.level }}</small>
              </th>
            </tr>
            <tr>
              <th scope="col" class="bg-black">
                <img :src="require(`../assets/${course.img}`)" class="img-fluid" alt="hero feature" width="50%" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-uppercase fw-bold text-grey">
                <span class="h5 fw-bold text-light">{{ course.fee }}</span><br>
                <span><small>{{ course.contract }}</small></span><br>
                <span><small>{{ course.unlimited }}</small></span>
              </td>
            </tr>
            <tr>
              <td class="text-uppercase fw-bold text-grey">
                <span class="h5 fw-bold text-light">{{ course.advancepay }}</span><br>
                <span><small><small>{{ course.payable }}</small></small></span><br>
                <span><small><small>{{ course.payOnly }}</small></small></span>
              </td>
            </tr>
            <tr>
              <td class="text-uppercase fw-bold text-grey pb-2">
                <div class="d-flex justify-content-between align-items-center flex-column" style="min-height:10rem">
                  <div class="h5 fw-bold text-light">
                    {{ course.desc }}
                  </div>
                  <div>
                    <span v-for="(tech, index) of course.techstack" :key="index" class="badge text-bg-secondary text-uppercase me-1">{{ tech }}</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="!!course.examboards">
              <td class="text-uppercase text-grey fw-bold">
                <span class="h5 fw-bold text-light">{{ course.boards }}</span><br>
                <span v-for="(board, index) in course.examboards" :key="index" class="badge text-bg-secondary text-uppercase me-1">{{ board }}</span>
              </td>
            </tr>
            <tr>
              <td class="text-uppercase text-grey fw-bold">
                <span class="h5 fw-bold text-light">{{ course.dropin }}</span><br>
                <span><small>{{ course.duration }}</small></span><br>
                <span><small>{{ course.classes }}</small></span>
              </td>
            </tr>
            <tr>
              <td class="text-uppercase text-grey fw-bold">
                <span class="h5 fw-bold text-light">{{ course.dropinout }}</span><br>
                <span><small><small>{{ course.sessions }}</small></small></span><br>
                <span><small><small>{{ course.repetitive }}</small></small></span>
              </td>
            </tr>
            <tr>
              <td class="bg-black py-2">
                <a href="mailto:MathsHourUK@gmail.com?subject=Regarding tuition" type="button" class="btn btn-secondary w-50">Email Leo (tutor)</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .bg-course {
    background: url('../assets/bg_icons.png') 0 100% repeat transparent;
  }
</style>