<script setup>
import { inject } from 'vue';

const store = inject('store');
</script>

<template>
  <router-link to="/" class="navbar-brand text-light">
    <div class="fs-1">
      {{ store.state.brand.first }}<span class="text-grey">{{ store.state.brand.second }}</span>
    </div>
    <span class="fs-4 text-lowercase text-grey">{{ store.state.brand.caption }}</span>
  </router-link>
</template>