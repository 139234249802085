import { createApp } from 'vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import App from './App.vue'
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faUserSecret)

const firebaseConfig = {
  apiKey: "AIzaSyD6U1JbDxa6y4LasqUrLMBNC1_DJK5hrIo",
  authDomain: "mathshouruk.firebaseapp.com",
  projectId: "mathshouruk",
  storageBucket: "mathshouruk.appspot.com",
  messagingSenderId: "1049476569279",
  appId: "1:1049476569279:web:153500fe7af59e04bb0e0a"
};

firebase.initializeApp(firebaseConfig);

let app;

firebase.auth().onAuthStateChanged(user => {
  if(!app) {
    console.log(user?.email.replace(user?.email, 'Hello there!'));
    
    app = createApp(App)
            .component('font-awesome-icon', FontAwesomeIcon)
            .use(router)
            .mount('#app');
  }
});